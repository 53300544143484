fieldset,
label {
    margin: 0;
    padding: 0;
}

/****** Style Star Rating Widget *****/

.rating {
    border: none;
    float: left;
    margin: 10% 0 0;
    padding: 0;

    label {
        margin: 0;
        padding: 0;
    }
}

.rating > input {
    display: none;
}
.rating > label:before {
    margin: 0.3rem;
    font-size: 2.5rem;
    font-family: FontAwesome;
    display: inline-block;
    content: '\f005';
}

.rating > label {
    color: #ddd;
    float: right;
}

/***** CSS Magic to Highlight Stars on Hover *****/

.rating > input:checked ~ label, /* show gold star when clicked */
.rating:not(:checked) > label:hover, /* hover current star */
.rating:not(:checked) > label:hover ~ label {
    color: #ffd700;
} /* hover previous stars in list */

.rating > input:checked + label:hover, /* hover current star when changing rating */
.rating > input:checked ~ label:hover,
.rating > label:hover ~ input:checked ~ label, /* lighten current selection */
.rating > input:checked ~ label:hover ~ label {
    color: #ffed85;
}
