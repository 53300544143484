$checkbox-size: 1.5rem;
$label-padding: 1rem;

input[type='checkbox'] {
    display: none;
}

input[type='checkbox'] + label {
    display: block;
    position: relative;
    padding-left: $checkbox-size + $label-padding;
    line-height: $checkbox-size;
    margin-bottom: 1rem;
    font-size: $checkbox-size;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

input[type='checkbox'] + label:last-child {
    margin-bottom: 0;
}

input[type='checkbox'] + label:before {
    content: '';
    display: block;
    width: $checkbox-size;
    height: $checkbox-size;
    border: 2px solid #000000;
    position: absolute;
    box-sizing: border-box;
    left: 0;
    top: 0;
    opacity: 0.6;
    -webkit-transition: all 0.4s, border-color 0.2s;
    transition: all 0.4s, border-color 0.2s;
}

input[type='checkbox']:checked + label:before {
    width: 1rem;
    top: -5px;
    left: 5px;
    border-radius: 0;
    opacity: 1;
    border-color: #27aa0d;
    border-top-color: transparent;
    border-left-color: transparent;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}
