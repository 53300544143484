.App {
    width: 100vw;
    height: 100vh;
    display: flex;
    margin: 0 auto;

    &.loggedIn {
        background: linear-gradient(45deg, #405de6, #5851db, #833ab4, #c13584, #e1306c, #fd1d1d);
    }

    &.notLoggedIn {
        background: #2c3338;
        color: #eeeeee;
    }
}

.button {
    display: inline-block;
    font-size: 1em;
    padding: 1em 1.5em;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    position: relative;
    box-shadow: 0 2px 25px black;

    &:disabled {
        background-color: #cccccc;
        color: #666666;
        box-shadow: 0 2px 25px rgba(141, 141, 141, 0.5);
        cursor: none;
        touch-action: none;
    }
}

$fuschia: #ff0081;
$next-button-bg: $fuschia;
$next-button-text-color: #fff;
$taxi-button-bg: #ffcc00;
$taxi-button-text-color: #000;

.taxi-button {
    margin: 10% 0;
    background-color: $taxi-button-bg;
    color: $taxi-button-text-color;
    box-shadow: 0 2px 25px rgba(255, 204, 0, 0.5);
}

.next-button {
    background-color: $next-button-bg;
    color: $next-button-text-color;
    box-shadow: 0 2px 25px rgba(255, 0, 130, 0.5);
}

.content-image {
    width: 100%;
}

.without-margin {
    margin: 0;
}

.warning:before {
    margin: 0 0.3rem;
    font-size: 1rem;
    font-family: FontAwesome;
    display: inline-block;
    color: #ff2525;
    font-style: normal;
    content: '\f06a';
}

img {
    margin: 5% 0;
}

.important {
    margin: 10% 0;
}
