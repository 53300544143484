.card {
    &__container {
        width: 100%;
        max-height: 90%;
        margin: auto;
        box-shadow: 0px 8px 60px -10px rgb(13, 28, 39 / 60%);
        background: #fff;
        border-radius: 12px;
        max-width: 85%;
        position: relative;

        .notLoggedIn &{
            background: #363b41;
        }



        h1 {
            text-align: center;
            margin: 10% 5%;

            .notLoggedIn &{
                font-size: 1.5rem;
            }
        }

        main,
        footer {
            padding: 0 10% 10%;
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        main {
            p {
                text-align: center;
            }
        }

        footer {
            display: flex;
            justify-content: center;
        }
    }
}
