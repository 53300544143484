html,
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 16px;
}

input {
    height: 3rem;
    width: 100%;
    font-size: 2rem;
    background: #3b4148;
    border: 0;
    color: #eee;
    border-radius: 8px;
    padding: 0.5rem;
    text-align: center;
}

@font-face {
    font-family: 'FontAwesome';
    src: url('./fonts/fa-solid-900.eot');
    src: url('./fonts/fa-solid-900.eot') format('embedded-opentype'), url('./fonts/fa-solid-900.ttf') format('truetype'),
        url('./fonts/fa-solid-900.woff') format('woff'), url('./fonts/fa-solid-900.svg') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

@media only screen and (-webkit-min-device-pixel-ratio: 2.625),
    only screen and (min--moz-device-pixel-ratio: 2.625),
    only screen and (-o-min-device-pixel-ratio: 2.625/1),
    only screen and (min-device-pixel-ratio: 2.625),
    only screen and (min-resolution: 405dpi),
    only screen and (min-resolution: 2.625dppx),
    only screen and (min-width: 412px) and (max-width: 767px),
    only screen and (min-height: 914px) {
}
